import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
import GreenWrapper from '../components/wrappers/greenWrapper'

const HeroText = styled.div`
  h1 {
    margin-top: 0;
  }
  h1,
  p {
    color: white;
    text-align: center;
  }

  .HeroText_P {
    display: flex;
    max-width: var(--site-width);
    margin: 0 auto;
  }
`

const JobDescription = styled.div`
  h3 {
    text-align: left;
    color: var(--green);
    margin-top: 0;
    padding-top: 0;
  }

  @media (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const CareersPage = () => {
  const email = 'info@ecotechrestoration.ca'
  const subject = 'Applying for Emergency Restoration Technician'
  const body = 'Please remember provide your cover letter and resume!'

  const mailtoLink = generateMailtoLink(email, subject, body)

  return (
    <Layout>
      <Seo
        title="Career Opportunites at Ecotech Restoration"
        description="Apply today at our full service restoration company
              providing emergency response, restoration, and reconstruction
              services to residential, commercial, industrial, and insurance
              customers. We offer a wide range of services that remediate
              conditions caused by flood, fire, water, wind, and other hazards."
        canonical="https://ecotechrestoration.ca/careers/"
      />

      <div
        css={css`
          max-height: 800px;
          width: 100%;
          overflow: hidden;
          display: flex;
          align-items: flex-end;
        `}>
        <StaticImage
          src="../images/heros/careers-cetteup-IC5sX-7PRN8-unsplash.jpg"
          quality={95}
          // imgStyle={{
          //   position: 'absolute',
          //   bottom: '0',
          //   verticalAlign: 'bottom',
          // }}
          alt="Ecotech Restoration Career Splash"
        />
      </div>

      {/* Hero Text */}
      <GreenWrapper>
        <HeroText>
          <h1>Career Opportunites at Ecotech Restoration</h1>
          <div className="HeroText_P">
            <div>
              <p>
                Ecotech Restoration Inc. is a full service restoration company
                providing emergency response, restoration, and reconstruction
                services to residential, commercial, industrial, and insurance
                customers. We offer a wide range of services that remediate
                conditions caused by flood, fire, water, wind, and other
                hazards. We pride ourselves on superior customer service and
                satisfaction.', 'We are committed to fostering a culture of
                teamwork, encouraging personal development and providing
                opportunities for growth within our organization. We offer
                competitive salaries, overtime pay, extended health benefits and
                other employee incentives such as paid tuition for training and
                employee referral bonuses.
              </p>
            </div>
          </div>
        </HeroText>
      </GreenWrapper>
      {/* ----- Heros END */}

      <SiteWidthWrapper>
        <h2
          css={css`
            color: var(--green);
          `}>
          Job Title:
        </h2>
        <h3
          css={css`
            color: var(--blue);
          `}>
          F/T Experienced Emergency Restoration Technician
        </h3>
        <JobDescription>
          <div>
            <h3>Job Description:</h3>
            <p>
              We are currently seeking a full-time experienced Emergency
              Restoration Technician for our Emergency Division, attending to
              water, fire, and smoke damage claims.
            </p>
            <p>
              Typical work hours are 9:00 AM to 5:00 PM with some required paid
              overtime, double time, and participation in a rotating on-call
              schedule.
            </p>
            <p>
              A Water Remediation Technician certificate (WRT) is considered an
              asset but we are willing to give the right candidate the
              opportunity to attend and complete IICRC training courses.
            </p>
          </div>
          <div>
            <p
              css={css`
                font-weight: bold;
              `}>
              In addition, you must be able to:
            </p>
            <ul>
              <li>
                Load, unload, and transport renovation construction materials
              </li>
              <li>Assist in demolition of affected walls and structures</li>
              <li>Clean and pile salvaged and affected materials</li>
              <li>
                Clean contaminants and apply chemical treatments to affected
                areas
              </li>
              <li>Remove rubble and other debris at restoration sites</li>
              <li>Ability to multi-task and make decisions</li>
              <li>
                Ability to respond to 24-hour emergency calls while on-call
              </li>
              <li>
                Be flexible, adaptable, and able to work efficiently in loud or
                busy environments
              </li>
              <li>
                Hold or acquire a valid BC driver's license with a clean record
              </li>
              <li>Maintain excellent customer service skills</li>
              <li>
                Hold or be willing to acquire an IICRC Certification in Water
                Damage
              </li>
            </ul>
          </div>
        </JobDescription>
        <div>
          <p>
            If you're interested in applying to this job, please email your
            resume and cover letter, and make sure to include the job you're
            applying for in the title of the e-mail.
          </p>
          <div
            css={css`
              text-align: center;
            `}>
            <a
              className="buttons_buttonWrapper"
              href={mailtoLink}
              aria-label="apply for Emergency Restoration Technician"
              target="_blank"
              rel="noreferrer">
              Apply Now
            </a>
          </div>
        </div>
      </SiteWidthWrapper>
    </Layout>
  )
}
export default CareersPage

function generateMailtoLink(email, subject, body) {
  const encodedEmail = encodeURIComponent(email)
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)

  const mailtoUrl = `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`

  return mailtoUrl
}
